import React, { useEffect } from "react"
import { connect } from "react-redux"
import moment from "moment"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider2 from "../components/slider2"
import { getOrderDetail } from "../store/actions/orderActions"
import { BASE_URL } from "../utils/urls"

const pageName = "DETAIL COMMANDE"
const Order = ({ orderId, isLoading, getOrderDetail, order }) => {
  useEffect(() => {
    getOrderDetail(orderId)
  }, [])
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      {isLoading ? (
        <div className="flex container mx-auto py-6">
          <div
            className="w-full mx-4 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative text-center"
            role="alert"
          >
            <span className="block sm:inline">Chargement en cours ...</span>
          </div>
        </div>
      ) : (
        <div className="container mx-auto py-6">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/3 rounded shadow py-4 mx-2 px-2">
              <div className="text-base pb-2 border-b">
                Historique de la commande
              </div>
              <div className="flex flex-col py-2 text-sm">
                {order.steps &&
                  order.steps.reverse().map((step, key) => (
                    <div className="flex flex-row" key={step.id}>
                      <li className="font-semibold text-xs text-left w-1/2">
                        {step.name}
                      </li>
                      <div className="text-right text-xs italic w-1/2">
                        {moment(step.pivot.created_at).format(
                          "DD/MM/YYYY à HH:mm"
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="w-full md:w-3/5 rounded shadow py-4 mx-2 px-2">
              <div className="text-base pb-2 border-b">
                Détail de la commande
              </div>
              <div className="flex flex-wrap text-sm">
                <div className="w-1/2 py-2 flex flex-col">
                  <div className="font-semibold">Référence de la commande</div>
                  <div>{order.ref}</div>
                </div>
                <div className="w-1/2 py-2 flex flex-col">
                  <div className="font-semibold">Adresse de livraison</div>
                  <div>{order.address ? order.address.content : ""}</div>
                </div>
                <div className="w-1/2 py-2 flex flex-col">
                  <div className="font-semibold">Moyen de paiement</div>
                  <div>
                    {order.payment_method ? order.payment_method.name : ""}
                  </div>
                </div>
                {order.payment_ref && (
                  <div className="w-1/2 py-2 flex flex-col">
                    <div className="font-semibold">Référence du paiement</div>
                    <div>{order.payment_ref}</div>
                  </div>
                )}
              </div>

              <div className="text-base pt-6 pb-2 border-b">Vêtements</div>
              <div className="flex flex-wrap">
                <div className="w-full">
                  <table className="table-auto text-sm">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 text-center"></th>
                        <th className="px-4 py-2 text-center">Produit</th>
                        <th className="px-4 py-2 text-center">Prix unitaire</th>
                        <th className="px-4 py-2 text-center">Quantité</th>
                        <th className="px-4 py-2 text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.products &&
                        order.products.map((product, key) => (
                          <tr key={product.id}>
                            <td className="border px-4 py-2 text-center">
                              <img
                                src={BASE_URL + "/storage/" + product.cover}
                                className="w-16 mx-auto"
                                alt=""
                                srcSet=""
                              />
                            </td>
                            <td className="border px-4 py-2 text-center">
                              {product.name}
                            </td>
                            <td className="border px-4 py-2 text-center">
                              {product.selling_price + " FCFA"}
                            </td>
                            <td className="border px-4 py-2 text-center">
                              <span className="px-2 text-center">
                                {product.pivot.quantity}
                              </span>
                            </td>
                            <td className="border px-4 py-2 text-center">
                              {product.selling_price * product.pivot.quantity +
                                " FCFA"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="w-full flex flex-row-reverse">
                  <div className="w-1/2">
                    <div className="flex flex-wrap text-sm py-1">
                      <span className="w-1/2 text-left">Sous total</span>
                      <span className="w-1/2 text-right">
                        {order.sub_total_price} FCFA
                      </span>
                    </div>
                    <hr />
                    <div className="flex flex-wrap text-sm py-1">
                      <span className="w-1/2 text-left">
                        Frais de Livraison
                      </span>
                      <span className="w-1/2 text-right">
                        {order.shipping_price} FCFA
                      </span>
                    </div>
                    <hr />
                    <div className="flex flex-wrap text-base py-2">
                      <span className="w-1/2 font-bold text-left">Total</span>
                      <span className="w-1/2 font-bold text-right">
                        {order.total_price} FCFA
                      </span>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    isLoading: state.order.isLoading,
    order: state.order.order,
  }
}

const mapDispatchToProps = {
  getOrderDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(Order)
